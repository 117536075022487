import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import classNames from "classnames";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Poppers from "@material-ui/core/Popper";
// import Divider from "@material-ui/core/Divider";
import Person from "@material-ui/icons/Person";
// import { IconButton } from "@material-ui/core";

// const useStyles = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  dropdownItem: {
    "&:active": {
      background: "#fff",
    },
  },
  buttonLink: {
    color: theme.palette.primary.main,
  },
  person: {
    margin: 8,
    color: theme.palette.primary.main,
  },
}));

const AdminNavbarLinks = () => {
  const classes = useStyles();

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <div>
      <div className={classes.manager}>
        {/* <IconButton onClick={handleClickProfile} className={classes.buttonLink}> */}
        <Person className={classes.person} />
        {/* </IconButton> */}
        {/* <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
            // {...TransitionProps}
            // id="profile-menu-list-grow"
            // style={{
            //   transformOrigin:
            //     placement === "bottom" ? "center top" : "center bottom",
            // }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profil
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Einstellungen
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Ausloggen
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers> */}
      </div>
    </div>
  );
};

export default AdminNavbarLinks;
