import { makeStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
  },
  btn: {
    color: theme.palette.primary.light,
    padding: "0.5rem",
    fontWeight: "bold",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  icon: {
    fontSize: "2rem",
    marginRight: "1rem",
  },
  paper: {
    textAlign: "start",
    marginTop: "2rem",
  },
  collapse: {
    transition: "1s",
  },
  uploads: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    display: "none",
  },
  paperImage: {
    width: "100%",
    marginBottom: "1rem",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  gridBtn: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  saveBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  text: {
    marginTop: "2rem",
  },
  image1: {
    borderRadius: "5px",
    boxShadow: "0.5px 0.5px 5px #000",
  },
  image2: {
    borderRadius: "5px",
    marginTop: "1rem",
    boxShadow: "0.5px 0.5px 5px #000",
  },
  upload: {
    paddingRight: "1rem",
  },
  uploadBox: {
    width: "80%",
  },
  divider: {
    width: "80%",
  },
}));

export default useStyles;
