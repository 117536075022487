import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AuthenticatedLayout from "../layout/Layout";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Paper } from "@material-ui/core";
import EditProfile from "../forms/EditProfile";
import EditAccountDetails from "../forms/EditAccountDetails";
import EditPassword from "../forms/EditPassword";
import UnsubscribeDialog from "../pages/Unsubscribe";
import Message from "../Message";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "3rem",
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    paddingTop: "20",
  },
  tabs: {
    [theme.breakpoints.down("sm")]: {
      flexDirecion: "vertical",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Settings = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.message.removeMessage());
    };
  }, []);

  return (
    <AuthenticatedLayout>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.root}>
          <Typography variant="h2" gutterBottom align="left">
            Einstellungen
          </Typography>
          <Message />
          <Paper position="static" className={classes.paper}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              variant="fullWidth"
              centered
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab label="Persönlich" />
              <Tab label="Bankverbindung" />
              <Tab label="Sicherheit" />
              <Tab label="Kündigung" />
            </Tabs>
          </Paper>
          <TabPanel value={currentTab} index={0}>
            <EditProfile />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <EditAccountDetails />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <EditPassword />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <UnsubscribeDialog />
          </TabPanel>
        </Container>
      </div>
    </AuthenticatedLayout>
  );
};

export default Settings;
