import React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "../../api";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/login`) {
    if (typeof window !== `undefined`) {
      navigate("/login");
    }

    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
