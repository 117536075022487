import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Warning } from "../Alerts";

const itemsPerPage = 5;
const ComplexList = ({ data, children }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState();
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data?.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(data?.length / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      <ul>
        {data.length == 0 ? (
          <Warning message="Keine Einträge vorhanden!" link="" />
        ) : currentItems.length > 0 ? (
          currentItems.map((d) => <li key={d.id}>{children(d)}</li>)
        ) : (
          <Warning
            message="Bitte klicken Sie erneut auf Seite 1, um das Ergebnis anzuzeigen! shafiq"
            link=""
          />
        )}
      </ul>
      <div className="flex justify-between">
        {!!data?.length && (
          <div>
            <p className="text-sm text-gray-700">
              {" "}
              <span className="font-medium">{itemOffset + 1}</span> bis{" "}
              <span className="font-medium">
                {endOffset <= data?.length ? endOffset : data?.length}
              </span>{" "}
              von insgesamt <span className="font-medium">{data?.length}</span>{" "}
              Fahrzeugen
            </p>
          </div>
        )}
        <ReactPaginate
          breakLabel="..."
          nextLabel="vor >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< zurück"
          renderOnZeroPageCount={null}
          containerClassName=" flex justify-center my-4 items-center"
          pageClassName=""
          pageLinkClassName="border mx-1 p-2 rounded hover:shadow-lg"
          nextLinkClassName="border p-2 border rounded hover:shadow-lg"
          previousLinkClassName="border p-2 border rounded hover:shadow-lg"
          activeLinkClassName="bg-lightGreen text-white p-2"
          disabledClassName=" hover:bg-blue-gray-200 hover:opacity-50 hover:cursor-not-allowed"
          disabledLinkClassName="hover:cursor-not-allowed"
        />
      </div>
    </div>
  );
};

export default ComplexList;
