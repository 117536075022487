import React, { useState, useEffect } from "react";
import { Typography, Container } from "@material-ui/core";

import store from "../../../redux/store";
import AuthenticatedLayout from "../layout/Layout";
import AddVehicle from "./AddVehicle";
import Dialog from "../dialog";
import { useSelector } from "react-redux";
import Message from "../Message";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Banner, DialogBox } from "../../subPage";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { BASE_URL } from "../../../api/config";

import useStyles from "../../../styles/default";
import Link from "../../Link";

const Default = () => {
  const classes = useStyles();
  const quotes = useSelector((state) => state.quotes.items);
  const { trackPageView } = useMatomo();
  const [open, setOPen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const handleClose = () => {
    setOPen(false);
    setIsBannerOpen(true);
  };
  const handleBannerClose = () => {
    setIsBannerOpen(false);
  };

  React.useEffect(() => {
    trackPageView();
  }, []);

  const [showMessage, setShowMessage] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowMessage(false);
    }, 10000);
  }, [showMessage]);

  const setMessage = () => {
    setShowMessage(true);
  };

  useEffect(async () => {
    const token = store.getState()?.user?.token;
    const response = await fetch(`${BASE_URL}/thg/quote-years`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "*",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response?.json();

    const renewableQuotes = json.data.filter(
      (quote) => quote.year === `${new Date().getFullYear() - 1}`
    );

    if (renewableQuotes.length > 0) {
      setOPen(true);
    }
  }, []);

  return (
    <AuthenticatedLayout>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom>
            Ihre Fahrzeuge
          </Typography>
          {!store.getState().user?.subscription?.unsubscribed_at ? (
            <>
              <Typography>
                <strong>HINWEIS</strong>: Leider können wir keine Fahrzeuge mehr
                für 2024 annehmen. Wir arbeiten aber daran, dass Sie in Kürze
                Ihre Fahrzeuge schon wieder für 2025 anmelden können.
              </Typography>
              <Typography className={classes.fahrzeuge}>
                Sie haben es fast geschafft - Laden Sie abschließend die
                Vorderseite der Zulassungsbescheinigung Teil I (Fahrzeugschein)
                hoch. Danach kümmern wir uns für Sie um den Rest. Haben Sie
                Fragen, erreichen Sie uns{" "}
                <Link href="/contact" className="underline">
                  hier
                </Link>
                .
              </Typography>
              <Typography>
                Profitieren Sie jetzt von unserem neuen Gutschein-Angebot!
                Wählen Sie ganz einfach anstatt der Auszahlung des Zusatzerlöses
                einen attraktiven, im Wert höheren Gutschein von einem unserer
                nachhaltigen Partnerunternehmen aus. Hier gelangen Sie zu
                unserem{" "}
                <Link
                  href="https://www.gutscheine-thgquoten.com/"
                  className="underline"
                >
                  THG-Gutscheinportal
                </Link>{" "}
                und finden Details zu den Gutscheinen unserer Partner. Die
                Auswahl des Gutscheins treffen Sie im nächsten Schritt. Drücken
                Sie dafür auf Fahrzeug hinzufügen.
              </Typography>
            </>
          ) : (
            <Box my={2} align="start" style={{ width: "100%" }}>
              <Alert severity="warning">
                <AlertTitle variant="h6">Kündigung vorgemerkt!</AlertTitle>
                <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                  &quot;Sie haben unseren THG-Quotenservice gekündigt. Daher
                  können Sie keine weiteren Fahrzeuge hinzufügen. Sollten Sie
                  unseren Service jedoch wieder nutzen wollen, müssen Sie Ihr
                  Konto im Bereich &apos;Persönliche Einstellungen&apos;
                  reaktivieren.&quot;{" "}
                </Typography>
              </Alert>
            </Box>
          )}
          <Dialog />
          {!store.getState().user?.subscription?.unsubscribed_at
            ? quotes.length < 100 && <AddVehicle setMessage={setMessage} />
            : null}
          {showMessage ? <Message /> : null}
        </Container>
        {!store.getState().user?.subscription?.unsubscribed_at ? (
          <>
            <DialogBox handleClose={handleClose} isOpen={open} />
            <Banner
              handleBannerClose={handleBannerClose}
              isBannerOpen={isBannerOpen}
            />
          </>
        ) : null}
      </div>
    </AuthenticatedLayout>
  );
};

export default Default;
