import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const DialogBox = ({ handleClose, isOpen }) => {
  const year = new Date().getFullYear();

  return (
    <div>
      <Dialog open={isOpen}>
        <DialogTitle
          sx={{
            fontSize: 18,
            m: "auto",
            width: "fit-content",
          }}
        >
          Sie haben nun die Möglichkeit, die THG-Quote Ihrer aktuellen Fahrzeuge
          auch für {year} zu beantragen. Klicken Sie dafür bitte auf den
          nachfolgenden Button, um zur Verlängerungsseite zu gelangen.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="flex justify-start items-center">
              <button className="px-6 py-2 rounded  bg-lightGreen text-white hover:shadow-lg transition-all ease-in duration-200 hover:bg-darkGreen">
                <a href={"/authenticated/renew"}>Jetzt verlängern!</a>
              </button>
              <button
                onClick={handleClose}
                className="border border-lightGreen mx-4 px-6 py-2 rounded text-lightGreen hover:shadow-md"
              >
                Später
              </button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogBox;
