import React from "react";

const CheckBox = ({ onCheck, name, label, error, value }) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={name}
          aria-describedby="comments-description"
          name={name}
          onChange={(e) => onCheck(e)}
          type="checkbox"
          defaultChecked={value}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={name}
          className="font-[400] text-[1.15rem] leading-[1.5rem] text-darkGreen"
        >
          {label}
        </label>
        <p className="mt-2 text-sm text-red-800" id={name}>
          {error}
        </p>
      </div>
    </div>
  );
};

export default CheckBox;
