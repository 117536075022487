import React, { useState, useEffect } from "react";
import AuthenticatedLayout from "../layout/Layout";
import { getQuotes } from "../../../api";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { Container } from "@material-ui/core";
import { Head, Info, Description, QuoteStatus } from "../quoteList";
import useStyles from "../../../styles/listVehicle";
import ComplexList from "../../ComplexList";
import BounceLoader from "../../BounceLoader";

var filteredQ = [];
const Vehicles = () => {
  const classes = useStyles();
  const [current, setCurrent] = useState(new Date().getFullYear());
  const quotes = useSelector((state) => state.quotes.items);
  const loading = useSelector((state) => state.quotes.loading);
  const [status, setStatus] = useState(-1);
  const handleClick = (year) => {
    setCurrent(year);
  };
  useEffect(() => {
    const unauthorizedCallback = () => {
      if (typeof window !== "undefined") {
        navigate("/login");
      }
    };
    getQuotes(unauthorizedCallback);
  }, []);

  const handleQuoteStatus = (status) => {
    setStatus(status);
  };

  switch (status) {
    case 4:
      filteredQ = quotes?.filter(
        (q) => q.year == current && q.status !== "paid"
      );
      break;
    case 5:
      filteredQ = quotes?.filter((q) => {
        return q.quote_years?.find(
          (q) => q.year == current && q.status === "paid"
        );
      });
      break;
    default:
      filteredQ = quotes?.filter((q) => q.year == current);
      break;
  }

  return (
    <AuthenticatedLayout>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Description />
          <>
            <Info handleQuoteStatus={handleQuoteStatus} />
            <Head current={current} handleClick={handleClick} />
            <div>
              {loading ? (
                <ComplexList data={filteredQ}>
                  {(quote) => <QuoteStatus year={current} quote={quote} />}
                </ComplexList>
              ) : (
                <BounceLoader />
              )}
            </div>
          </>
        </Container>
      </div>
    </AuthenticatedLayout>
  );
};

export default Vehicles;
