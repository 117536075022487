import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout/Layout";
import Settings from "../components/authenticated/pages/Settings";
import AddVehicle from "../components/authenticated/pages/AddVehicle";
import Default from "../components/authenticated/pages/Default";
import Step from "../components/authenticated/pages/Step";
import PrivateRoute from "../components/authenticated/PrivateRoute";
import Logout from "../components/authenticated/pages/Logout";
import Vehicles from "../components/authenticated/pages/Vehicles";
import { navigate } from "gatsby";
import Renew from "../components/authenticated/pages/renew";

const NotFound = () => {
  if (typeof window !== "undefined") {
    navigate("/404");
  }
  return null;
};

const Authenticated = () => (
  <Layout>
    <Router basepath="/authenticated">
      <PrivateRoute path="/" component={Default} />
      <PrivateRoute path="/vehicle" component={AddVehicle} />
      <PrivateRoute path="/renew" component={Renew} />
      <PrivateRoute path="/vehicles" component={Vehicles} />
      <PrivateRoute path="/personal" component={Settings} />
      <PrivateRoute path="/step" component={Step} />
      <PrivateRoute path="/logout" component={Logout} />
      <NotFound default />
    </Router>
  </Layout>
);

export default Authenticated;
