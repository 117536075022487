import * as Yup from "yup";

require("./globalScheme");
const editProfileScheme = Yup.object().shape({
  first_name: Yup.string()
    .isCharterOnly()
    .required("Bitte geben Sie einen Vornamen ein."),
  last_name: Yup.string()
    .isCharterOnly()
    .required("Bitte geben Sie einen Nachnamen ein."),
  street: Yup.string().required("Bitte geben Sie eine Adresse ein."),
  postal_code: Yup.string()
    .isValidPostalCode()
    .required("Bitte geben Sie eine Postleitzahl ein."),
  city: Yup.string().required("Bitte geben Sie eine Stadt ein."),
  password: Yup.string().required("Bitte geben Sie Ihr Passwort ein."),
  company: Yup.string().nullable(),
  tax_number: Yup.string().nullable(),
});

export default editProfileScheme;
