import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";

const MyIbanField = ({
  label,
  fullWidth,
  placeholder,
  required,
  type,
  name,
  ...props
}) => {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <InputMask
        mask="**** **** **** **** **** **** **** ****"
        disabled={false}
        maskChar=" "
        {...field}
      >
        {() => (
          <TextField
            variant="outlined"
            fullWidth={fullWidth}
            required={required}
            label={label}
            placeholder={placeholder}
            helperText={errorText}
            error={!!errorText}
            type={type}
            id={`${name}`}
            InputLabelProps={{
              htmlFor: `${name}`,
            }}
            inputProps={{
              autocomplete: "cc-number",
            }}
          />
        )}
      </InputMask>
    </>
  );
};

export default MyIbanField;
