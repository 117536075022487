import { makeStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  btn: {
    textAlign: "center",
  },
}));

export default useStyles;
