import React, { useState } from "react";
import { Formik, Form } from "formik";
import YupPassword from "yup-password";
import MyTextField from "../../fields/MyTextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Loading from "../../Loading";
import { updateUserPassword } from "../../../api";
import { Typography } from "@material-ui/core";
import { navigate } from "gatsby";
import { lighten } from "@material-ui/core/styles";
import editPasswordScheme from "../../../helper/schemes/editPasswordScheme";
import useStyles from "../../../styles/editSetting";
const yup = require("yup");
require("yup-password")(yup); // extend yup
YupPassword(yup); // extend yup

const initialValues = {
  password: "",
  new_password: "",
  new_password_confirmation: "",
};

const EditPassword = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const submitData = (data, formikProps) => {
    setLoading(true);
    const unauthorizedCallback = () => {
      if (typeof window !== `undefined`) {
        navigate("/login");
      }
    };

    updateUserPassword(data, unauthorizedCallback).then(() => {
      setLoading(false);
      formikProps.resetForm();
    });
  };

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editPasswordScheme}
      onSubmit={(values, formikProps) => {
        // same shape as initial values
        submitData(values, formikProps);
      }}
    >
      {(props) => (
        <Form>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
              <Grid container spacing={2}>
                <Typography variant="h6">Passwort ändern</Typography>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="current_password"
                    label="Altes Passwort"
                    name="current_password"
                    type="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Neues Passwort"
                    id="password"
                    type="password"
                    autoComplete="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password_confirmation"
                    label="Neues Passwort wiederholen"
                    name="password_confirmation"
                    type="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.btn}
                  justifyContent="flex-end"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Box mt={8}></Box>
          </Container>
          {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};

export default EditPassword;
