import React from "react";
import Sidebar from "../nav/Sidebar";
import stellantisLogo from "../../../media/images/stell-logo-thg.png";
import publicLogo from "../../../media/images/m3eThgLogo.png";
import bgImage from "../sidebar.png";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../nav/Navbar";
import Footer from "../nav/Footer";

import { container, drawerWidth, transition } from "../jss/core";

//hooks
//import { useRouteName } from "hooks";

const useStyles = makeStyles((theme) => ({
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  container,
  map: {
    marginTop: "70px",
  },
}));

const routes = [
  {
    href: "/authenticated/",
    name: "Fahrzeuge",
    //icon: Person,
    //component: UserProfile,
    layout: "/admin",
  },
  {
    href: "/authenticated/vehicles",
    name: "Fahrzeugliste",
    //icon: Person,
    //component: UserProfile,
    layout: "/admin",
  },
  {
    href: "/authenticated/personal",
    name: "Persönliche Einstellungen",
    //icon: Person,
    //component: UserProfile,
    layout: "/admin",
  },
  {
    href: "/authenticated/step",
    name: "So funktioniert's",
    //icon: Person,
    //component: UserProfile,
    layout: "/admin",
  },
  {
    href: "/contact",
    name: "Kontakt",
    //icon: Person,
    //component: UserProfile,
    layout: "/admin",
  },

  {
    href: "/faq",
    name: "FAQ",
    //icon: Person,
    //component: UserProfile,
    layout: "/faq",
  },
  {
    href: "https://www.gutscheine-thgquoten.com/",
    name: "Gutscheinportal",
    //icon: Person,
    //component: UserProfile,
    layout: "https://www.gutscheine-thgquoten.com/",
  },
];

const getLogo = () => {
  switch (process.env.GATSBY_BRANDING) {
    case "stellantis":
      return {
        src: stellantisLogo,
        height: undefined,
      };
    case "public":
      return {
        src: publicLogo,
        height: 70,
      };
    default:
      return undefined;
  }
};

const Layout = ({ children, ...rest }) => {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logo = getLogo();

  return (
    <>
      <Sidebar
        routes={routes}
        color="blue"
        logo={logo.src}
        image={bgImage}
        logoText="Creative Marco"
        className={classes.icon}
        logoHeight={logo.height}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
