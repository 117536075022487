import React from "react";

const description = () => {
  const year = new Date().getFullYear();
  return (
    <div>
      <h1 className="text-4xl font-[400] sm:leading-[4.5rem] tracking-tight text-darkGreen sm:text-5xl md:text-6xl">
        Verlängerung THG-Quoten für {year}
      </h1>
      <p className=" mt-[1.25rem]  mb-[2.5rem] font-[400] leading-[1.5rem] text-[1rem] text-darkGreen">
        Nun haben Sie die Möglichkeit, die THG-Quote all Ihrer bestehenden
        Fahrzeuge unkompliziert für {year} zu beantragen. In der nachfolgenden
        Übersicht finden Sie Ihre Fahrzeuge mit den Erlösen für das Jahr {year}{" "}
        - als Fixbetrag, wie es bei M3E üblich ist. Sie können über den
        Schieberegler für jedes Fahrzeug individuell auswählen, ob Sie die
        THG-Quote auch für {year} beantragen möchten. Sie behalten die volle
        Kontrolle über Ihre Entscheidungen. Nachdem Sie unsere AGB erneut
        akzeptiert haben und zur Bestätigung Ihr Passwort aus dem Login-Bereich
        eingegeben haben, erhalten Sie eine Bestätigung per E-Mail. Im Anschluss
        kümmern wir uns wie gewohnt um den Rest.
      </p>
    </div>
  );
};

export default description;
