import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../jss/navbarStyles";
import MenuIcon from "@material-ui/icons/Menu";
import { Button } from "@material-ui/core";
import { navigate } from "gatsby";
import MobileMenu from "./MobileMenu";

const useStyles = makeStyles(styles);

const Navbar = (props) => {
  // let greetingMessage = "";
  // if (isLoggedIn()) {
  //   greetingMessage = `Hello ${getUser().name}`;
  // } else {
  //   greetingMessage = "You are not logged in";
  // }

  const classes = useStyles();
  const [menu, setMenu] = useState(false);
  const antiMobile = useMediaQuery("@media (min-width:1210px)");
  useEffect(() => {
    if (antiMobile) {
      setMenu(false);
    }
  }, [antiMobile]);
  //const routeName = useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  return (
    <AppBar className={classes.appBar + appBarClasses} align="end">
      <Toolbar className={classes.container}>
        <div className={classes.flex}></div>
        <Hidden smDown>
          <Button
            onClick={() => {
              if (typeof window !== `undefined`) {
                navigate("/authenticated/logout");
              }
            }}
          >
            Logout
          </Button>
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={() => setMenu(!menu)}
          >
            <MenuIcon color="primary" />
          </IconButton>
        </Hidden>
      </Toolbar>
      {!antiMobile && (
        <MobileMenu state={menu} setState={setMenu} navigation={navigation} />
      )}
    </AppBar>
  );
};

export default Navbar;

const navigation = [
  {
    name: "Startseite",
    href: "/",
    external: false,
  },
  {
    name: "Fahrzeuge",
    href: "/authenticated/",
    external: false,
  },
  {
    href: "/authenticated/vehicles",
    name: "Fahrzeugliste",
    //icon: Person,
    //component: UserProfile,
    layout: "/admin",
  },
  {
    name: "Persönliche Einstellungen",
    href: "/authenticated/personal",
    external: false,
  },
  {
    name: "Schritt für Schritt Erklährung",
    href: "/authenticated/step",
    external: false,
  },
  {
    name: "Kontakt",
    href: "/contact",
    external: false,
  },

  {
    name: "FAQ",
    href: "/faq",
    external: false,
  },
];
