import React from "react";

const Head = ({ handleClick, current }) => {
  return (
    <div className="w-full h-12 bg-lightGreen rounded-md grid grid-cols-2 ">
      <div className="h-full  grid grid-cols-3 ">
        <div
          className={`flex justify-center items-center border-b-4 rounded  hover:cursor-pointer transition-all ease-in duration-100 ${
            current == new Date().getFullYear() - 1
              ? "border-red-500"
              : "border-lightGreen"
          }`}
          onClick={() => handleClick(new Date().getFullYear() - 1)}
        >
          <span className="text-white text-[1rem] font-[400] leading-[1.4rem]">
            {new Date().getFullYear() - 1}
          </span>
        </div>
        <div
          className={`flex justify-center items-center border-b-4 rounded  hover:cursor-pointer transition-all ease-in duration-100 ${
            current == new Date().getFullYear()
              ? "border-red-500"
              : "border-lightGreen"
          }`}
          onClick={() => handleClick(new Date().getFullYear())}
        >
          <span className="text-white text-[1rem] font-[400] leading-[1.4rem]">
            {new Date().getFullYear()}
          </span>
        </div>
        {/* <div
          className={` flex justify-center items-center border-b-4 rounded  hover:cursor-pointer transition-all ease-in duration-100 ${
            current == new Date().getFullYear() + 1
              ? "border-red-500"
              : "border-lightGreen"
          }`}
          onClick={(e) => handleClick(new Date().getFullYear() + 1)}
        >
          <span className="text-white text-[1rem] font-[400] leading-[1.4rem]">
            {new Date().getFullYear() + 1}
          </span>
        </div> */}
      </div>
      {/* <div className=" flex justify-end items-center">
        <button className="flex justify-center items-center px-4 hover:cursor-pointer ">
          <span className="text-white text-[1rem] font-[400] leading-[1.4rem] mr-2">
            Ordnen
          </span>
          <span>
            <img
              src={sortingIcon}
              alt="sort the quotes"
              className="w-[1.2rem] h-[1.2rem] "
            />
          </span>
        </button>
      </div> */}
    </div>
  );
};

export default Head;
