import React from "react";
import { ToggleField } from "../fields";
import { useSelector } from "react-redux";
import { Warning } from "../Alerts";
import { useEffect } from "react";
import { navigate } from "gatsby";
import { getPrices, getQuotes } from "../../api";

const TableOfContent = ({ checkedQuotes, setCheckedQuotes, next }) => {
  const year = new Date().getFullYear();
  const quotes = useSelector((state) => state.quotes.items);
  const prices = useSelector((state) => state.prices.items);
  const renewableQuotes = quotes
    .map((q) => ({
      ...q,
      newPrice:
        prices.find((price) => price.type === q.vehicle_type)?.price || null,
    }))
    .filter(
      (quote) =>
        quote.newPrice &&
        quote.year === `${new Date().getFullYear() - 1}` &&
        quote.is_renewable
    );
  const handleToggle = (qId, checked) => {
    setCheckedQuotes((quotes) => [
      ...quotes.filter((q) => q.id !== qId),
      ...(checked
        ? [
            {
              id: qId,
            },
          ]
        : []),
    ]);
  };

  useEffect(() => {
    const unauthorizedCallback = () => {
      if (typeof window !== "undefined") {
        navigate("/login");
      }
    };
    getPrices(unauthorizedCallback);
    getQuotes(unauthorizedCallback);
  }, []);

  const completeType = (type) => {
    switch (type) {
      case "m1":
        return "E-Auto (M1)";
      case "n1":
        return "Leichtes E-Nutzfahrzeug (N1)";
      case "n2":
        return "schweres E-Nutzfahrzeug (N2)";
      case "n3":
        return "E-Nutzfahrzeug ab 12 Tonnen (N3)";
      case "m3":
        return "E-Bus (M3)";
      case "other":
        return "Sonstige E-Fahrzege";
    }
  };

  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden md:rounded-lg">
            {renewableQuotes.length > 0 && (
              <table className="min-w-full ">
                <thead className="bg-[#F0F0F0]">
                  <tr className="shadow">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-[1rem] font-[700] leading-[1.5rem] text-lightGreen sm:pl-6"
                    >
                      Kennzeichen
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-[1rem] font-[700] leading-[1.5rem] text-lightGreen sm:pl-6"
                    >
                      Fahrzeugtyp
                    </th>
                    <th
                      scope="col"
                      className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Erlös für {year}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      THG-Quote für {year} beantragen?
                    </th>
                  </tr>
                </thead>
                <tbody className=" bg-white">
                  {renewableQuotes.map((quote, index) => {
                    return (
                      <tr className={"border-b border-[#F0F0F0]"} key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[1.25rem] font-[400] leading-[1.5rem] text-gray-900 sm:pl-6">
                          {quote.number_plate}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[1.25rem] font-[400] leading-[1.5rem] text-gray-900 sm:pl-6">
                          {completeType(quote.vehicle_type)}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[1.25rem] font-[400] leading-[1.5rem] text-gray-900 sm:pl-6">
                          {quote.newPrice} €
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-[1.25rem] font-[400] leading-[1.5rem] text-gray-900 sm:pl-6">
                          {!next ? (
                            <ToggleField
                              handleChange={handleToggle}
                              value={quote.id}
                              isPicked={false}
                            />
                          ) : checkedQuotes
                              .map((q) => q.id)
                              .includes(quote.id) ? (
                            "Ja"
                          ) : (
                            "Nein"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {renewableQuotes.length <= 0 && (
              <Warning message="Alle möglichen Fahrzeuge wurden bereits verlängert." />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableOfContent;
