import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ToggleField = ({ handleChange, id, label, value, isPicked }) => {
  const [enabled, setEnabled] = useState(isPicked);
  useEffect(() => {
    handleChange(value, enabled);
  }, [enabled]);

  return (
    <Switch.Group as="div" className="flex items-center my-4">
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? "bg-lightGreen" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lightGreen focus:ring-offset-2"
        )}
        id={id}
        value={value}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-bestWhite">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};
export default ToggleField;
