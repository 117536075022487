import React from "react";

const paymentMethods = [
  { id: "credit-card", title: "Credit card" },
  { id: "paypal", title: "PayPal" },
  { id: "etransfer", title: "eTransfer" },
];
const Info = ({ handleQuoteStatus }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-start items-start sm:justify-end sm:items-end mb-2">
      <fieldset className="mt-4">
        <legend className="sr-only">Payment type</legend>
        <div className="space-y-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 items-start">
          {/* <div className="flex items-center">
            <input
              name="quote-status"
              type="radio"
              defaultChecked
              onClick={() => handleQuoteStatus("rejected")}
              className="h-4 w-4 border-gray-300 text-[#FF3B30] focus:ring-[#FF3B30]"
            />

            <label
              htmlFor=""
              className="ml-2 block text-sm font-medium text-[#FF3B30]"
            >
              Prozess vorzeitig beendet
            </label>
          </div> */}
          <div>
            <label
              htmlFor=""
              className="ml-2 block text-sm font-bold text-black"
            >
              Filtern nach:
            </label>
          </div>
          <div className="flex items-center">
            <input
              name="quote-status"
              type="radio"
              defaultChecked
              onClick={() => handleQuoteStatus(5)}
              className="h-4 w-4 border-gray-300 text-[#34C759] focus:ring-[#34C759]"
            />

            <label
              htmlFor=""
              className="ml-2 block text-sm font-medium text-[#34C759]"
            >
              Prozess abgeschlossen
            </label>
          </div>
          <div className="flex items-center">
            <input
              name="quote-status"
              type="radio"
              defaultChecked
              onClick={() => handleQuoteStatus(4)}
              className="h-4 w-4 border-gray-300 text-[#FFCC00] focus:ring-[#FFCC00]"
            />

            <label
              htmlFor=""
              className="ml-2 block text-sm font-medium text-[#FFCC00]"
            >
              In Bearbeitung
            </label>
          </div>
          <div className="flex items-center">
            <input
              name="quote-status"
              type="radio"
              defaultChecked
              onClick={() => handleQuoteStatus(-1)}
              className="h-4 w-4 border-gray-300 text-black focus:ring-black"
            />

            <label
              htmlFor=""
              className="ml-2 block text-sm font-medium text-lightGreen"
            >
              Alle Fahrzeuge
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Info;
