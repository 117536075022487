import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import MyTextField from "../../fields/MyTextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import MyIbanField from "../../fields/MyIbanField";
import InputMask from "react-input-mask";
import { updateAccountInfo } from "../../../api";
import Loading from "../../Loading";
import { navigate } from "gatsby";
import { lighten } from "@material-ui/core/styles";
import editAccountScheme from "../../../helper/schemes/editAccountScheme";
import Box from "@material-ui/core/Box";
import MyCheckboxGroup from "../../fields/MyCheckboxGroup";

import CyptoJS from "../../../helper/cryptojs.js";
import useStyles from "../../../styles/editSetting";

const EditAccountDetails = ({ accountInfo }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    account_holder: accountInfo.account_holder || "",
    iban: "",
    password: "",
    has_iban: [],
  };

  const submitData = (data, formikProps) => {
    setLoading(true);
    const unauthorizedCallback = () => {
      if (typeof window !== `undefined`) {
        navigate("/login");
      }
    };

    updateAccountInfo(data, unauthorizedCallback).then(() => {
      setLoading(false);
      formikProps.resetForm();
    });
  };

  if (loading) return <Loading />;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editAccountScheme}
      onSubmit={(values, formikProps) => {
        const strippedIban = values.iban.replace(/ /g, "");

        // same shape as initial values
        submitData({ ...values, iban: strippedIban }, formikProps);
      }}
    >
      {(props) => (
        <Form autoComplete="off">
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
              <Grid container spacing={2}>
                <Typography variant="h6">Bankverbindung anpassen</Typography>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="account_holder"
                    label="Kontoinhaber"
                    name="account_holder"
                    autoComplete="cc-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  {accountInfo?.iban ? (
                    <InputMask
                      mask="**** **** **** **** **** **** **** ****"
                      disabled
                      maskChar=" "
                      formatChars={{
                        "*": "^[A-Z0-9*]*",
                      }}
                      defaultValue={
                        accountInfo.iban ? CyptoJS(accountInfo.iban) : null
                      }
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          size="medium"
                          name="old_iban"
                          label="Derzeitige IBAN"
                          id="old_iban"
                          disabled
                        />
                      )}
                    </InputMask>
                  ) : (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      size="medium"
                      name="old_iban"
                      label="Derzeitige IBAN"
                      defaultValue="Es wurde keine IBAN hinterlegt"
                      id="old_iban"
                      disabled
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <MyCheckboxGroup
                    label="Möchten Sie Ihre IBAN hinzufügen oder bearbeiten?"
                    options={[
                      {
                        label: (
                          <Box style={{ textAlign: "left" }}>
                            <Typography variant="overline">Ja</Typography>
                          </Box>
                        ),
                        value: "yes",
                      },
                    ]}
                    name="has_iban"
                  />
                </Grid>
                {props.values.has_iban.includes("yes") && (
                  <Grid item xs={12} align="left">
                    <MyIbanField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="iban"
                      label="Neue IBAN"
                      id="iban"
                      autoComplete="cc-number"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography>Bestätigung: </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Passwort"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12} className={classes.btn}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
          {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};

export default connect((state) => ({
  accountInfo: state?.user?.account,
}))(EditAccountDetails);
