import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh",
    paddingTop: "5rem",
    paddingBottom: "2rem",
  },
  fahrzeuge: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  paper: {
    padding: "1rem",
    marginBottom: "1rem",
  },
  chip: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "1rem",
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
  },
  grid: {
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
  },
}));

export default useStyles;
