import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "../../Link";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btn: {
    justifyContent: "start",
    paddingLeft: "0",
    color: theme.palette.primary.light,
  },
  btnRegist: {
    border: "1px solid #fff",
    color: theme.palette.primary.light,
  },
  list: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
    color: theme.palette.primary.light,
    width: "250px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function MobileMenu({ state, setState, navigation }) {
  const classes = useStyles();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={state}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div
        className={classes.list}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {navigation.map(({ name, href, external }, index) => (
            <ListItem
              key={index}
              button
              component={Link}
              href={href}
              underline="none"
              external={external}
              className={classes.listBox}
            >
              {/* <ListItemIcon></ListItemIcon> */}
              <ListItemText primary={name} />
            </ListItem>
          ))}
          <ListItem
            key="logout"
            button
            component={Link}
            href="/authenticated/logout"
            underline="none"
            className={classes.listBox}
          >
            <ListItemText>Ausloggen</ListItemText>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
}
