import React from "react";

const Description = () => {
  return (
    <div className="my-2">
      <h1 className="text-[40px] font-[400] sm:leading-[72px] text-darkGreen md:text-[60px]">
        Liste Ihrer Fahrzeuge
      </h1>
      <p className="mt-[21px] text-[16px] font-[400] leading-[24px] text-[#070606]">
        Hier finden Sie eine Übersicht all Ihrer registrierten Fahrzeuge in
        Ihrem aktuellen Bearbeitungsstatus. Mit den Reitern 2023 und 2024 können
        Sie zwischen den Bearbeitungszeiträumen wechseln.{" "}
        <a
          className="underline hover:text-blue-200"
          href="https://m3e.group/blog/m3e-thg-quoten-startet-neue-aktion-mit-charge-and-co-mit-einem-50-eur-wertgutschein-zusaetzlich"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mehr Details
        </a>
      </p>
    </div>
  );
};

export default Description;
