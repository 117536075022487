import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { logout } from "../../../api";

const Logout = () => {
  useEffect(() => {
    logout().then(() => {
      if (typeof window !== `undefined`) {
        navigate(`/login`);
      }
    });
  });

  return null;
};

export default Logout;
