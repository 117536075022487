import React, { useState } from "react";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import {
  FormControl,
  FormLabel,
  Box,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    width: 400,
    display: ({ fileUploaded }) => (fileUploaded ? "none" : "block"),
  },
  text: {
    fontSize: 28,
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "200px",
  },
  previewImg: {
    height: "100%",
  },
  pdfIcon: {
    height: "100%",
    maxWidth: 200,
    width: "100%",
  },
}));

const Error = ({ fileSizeExceeded, formikProps, name }) => {
  if (fileSizeExceeded) {
    return <FormHelperText error>Die Datei ist zu groß</FormHelperText>;
  }
  if (!!formikProps.touched[name] && !!formikProps.errors[name]) {
    return <FormHelperText error>{formikProps.errors[name]}</FormHelperText>;
  }
  return null;
};

const MyFileUpload = (props) => {
  const formikProps = useFormikContext();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [fileName, setFileName] = useState(null);
  const classes = useStyles({ fileUploaded });

  return (
    <Grid item xs={props.width}>
      <Box style={{ height: 275, display: "flex" }} mb={6}>
        <FormControl>
          <FormLabel style={{ fontWeight: 900, color: "#000" }}>
            {props.label}
          </FormLabel>
          <Box mt={2}>
            <DropzoneArea
              onDropRejected={() => setFileSizeExceeded(true)}
              classes={{
                root: classes.root,
                text: classes.text,
                textContainer: classes.textContainer,
              }}
              maxFileSize={
                props.filesLimit ? props.filesLimit * 3000000 : 3000000
              }
              showPreviews={true}
              showPreviewsInDropzone={false}
              previewGridProps={{
                container: {
                  spacing: 1,
                  direction: "column",
                  alignItems: "center",
                },
                item: { xs: 12 },
              }}
              getPreviewIcon={(file, _) => {
                if (file.file.type.split("/")[0] === "image") {
                  return (
                    <img
                      style={{ height: 235 }}
                      className={classes.previewImg}
                      role="presentation"
                      src={file.data}
                    />
                  );
                } else if (file.file.type.split("/")[1] === "pdf") {
                  return (
                    <Box style={{ height: 235 }}>
                      <PictureAsPdfIcon classes={{ root: classes.pdfIcon }} />
                    </Box>
                  );
                } else {
                  return (
                    <Box style={{ height: 235 }}>
                      <AttachFileIcon classes={{ root: classes.pdfIcon }} />
                    </Box>
                  );
                }
              }}
              previewText=""
              showAlerts={false}
              dropzoneText="Datei Anhängen"
              filesLimit={props.filesLimit ? props.filesLimit : 1}
              acceptedFiles={props.accept || [".jpg", ".jpeg", ".png", ".pdf"]}
              onChange={(files) => {
                setFileSizeExceeded(false);
                if (files[0]) {
                  formikProps.setFieldValue(props.name, files[0]);
                  setFileUploaded(true);
                  setFileName(files[0].name);
                } else {
                  formikProps.setFieldValue(props.name, "");
                  setFileUploaded(false);
                  setFileName(null);
                }
              }}
            />
          </Box>
          <FormHelperText>
            {fileName || "Maximale Dateigröße: 3 MB."}
          </FormHelperText>
          <Error
            fileSizeExceeded={fileSizeExceeded}
            formikProps={formikProps}
            name={props.name}
          />
        </FormControl>
      </Box>
    </Grid>
  );
};

export default MyFileUpload;
