import React from "react";
import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import AuthenticatedLayout from "../layout/Layout";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "95vh",
    paddingTop: "4rem",
  },
  paper: {
    margin: "1rem 0rem",
    padding: "1rem",
  },
  div: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  icon: {
    fontSize: "2rem",
    marginRight: "1rem",
    color: theme.palette.primary.main,
  },
  typo: {
    fontWeight: "bold",
  },
}));

const Step = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <AuthenticatedLayout>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom>
            So funktioniert's
          </Typography>
          <Grid container>
            <Typography variant="h6">
              Sie haben sich bereits erfolgreich registriert. Es fehlen nur noch
              wenige Schritte bis wir Ihre THG-Quoten registrieren können.
            </Typography>
            <Paper elevation={4} className={classes.paper}>
              <div className={classes.div}>
                <AccountBoxIcon className={classes.icon} />
                <Typography variant="h5" className={classes.typo}>
                  Persönliche Einstellungen
                </Typography>
              </div>
              <Typography variant="h6">
                Zunächst müssen Sie Ihr Profil vervollständigen. Geben Sie
                hierfür Ihre Kontaktdaten und Ihre Bankverbindung in dem Reiter
                “Persönliche Einstellungen” an und speichern Sie diese
                anschließend. Achten Sie darauf, dass der Name des Kontoinhabers
                mit Ihrem Registrierungsnamen und Namen auf dem Fahrzeugschein
                übereinstimmen.
              </Typography>
            </Paper>
            <Paper elevation={4} className={classes.paper}>
              <div className={classes.div}>
                <DriveEtaIcon className={classes.icon} />
                <Typography variant="h5" className={classes.typo}>
                  Fahrzeuge
                </Typography>
              </div>
              <Typography variant="h6">
                Zusätzlich müssen Sie im Reiter “Fahrzeuge” Ihr E-Fahrzeug(e)
                hinzufügen. Damit wir Ihr E-Fahrzeug eindeutig zuordnen können,
                geben Sie bitte zunächst das Nummernschild Ihres Fahrzeugs in
                dem dafür vorgesehenen Eingabefeld an. Wählen Sie im
                Dropdown-Menü die Fahrzeugklasse Ihres E-Fahrzeugs aus. Laden
                Sie anschließend einen Scan oder ein Foto Ihrer
                Zulassungsbescheinigung Teil I (Vorderseite) hoch. Nutzen Sie
                dafür das drag and drop- Feld. Sie können bis zu zehn Fahrzeuge
                registrieren. Geben Sie die Daten jedoch für jedes Fahrzeug
                einzeln an. Dies funktioniert über den Button “weiteres Fahrzeug
                hinzufügen”.
              </Typography>
            </Paper>
            <Typography variant="h6">
              Mehr müssen Sie nicht tun. In dem Reiter “Fahrzeuge” können Sie
              jederzeit den aktuellen Status Ihres Antrags nachverfolgen.
            </Typography>
          </Grid>
        </Container>
      </div>
    </AuthenticatedLayout>
  );
};

export default Step;
