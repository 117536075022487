import CryptoJS from "crypto-js";

const CryptoJs = (encryptStr) => {
  var Base64 = require("js-base64").Base64;
  var key = "0q2V8/90FYnWmEKpks13QMPHoEJhZLlEcr9eW0BMdhU=";
  encryptStr = CryptoJS.enc.Base64.parse(encryptStr);
  let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
  encryptData = JSON.parse(encryptData);
  let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
  var decrypted = CryptoJS.AES.decrypt(
    encryptData.value,
    CryptoJS.enc.Base64.parse(key),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
  if (decrypted != null) {
    const arrayResult = decrypted.split(":");
    return arrayResult[2]?.slice(1, -2);
  } else {
    return null;
  }
};
export default CryptoJs;
