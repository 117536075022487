import * as Yup from "yup";
import isValidIbanNumber from "../isValidIbanNumber";
require("./globalScheme");

const editAccountScheme = Yup.object().shape({
  /* iban: Yup.string()
    .isAlphaNumeric()
    .test(
      "ibanValidation",
      "Die eingegebene IBAN ist nicht korrekt.",
      (value) => isValidIbanNumber(value)
    ),
    */
  account_holder: Yup.string().isValidAccountHolder(),
  password: Yup.string().isValidPassword(),
  has_iban: Yup.array().of(Yup.mixed().oneOf(["yes"])),
  iban: Yup.string().when("has_iban", {
    is: (field) => field.includes("yes"),
    then: Yup.string()
      .isAlphaNumeric()
      .test(
        "ibanValidation",
        "Die eingegebene IBAN ist nicht korrekt.",
        (value) => isValidIbanNumber(value)
      ),
  }),
});

export default editAccountScheme;
