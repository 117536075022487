import * as Yup from "yup";
require("./globalScheme");
const editPasswordScheme = Yup.object().shape({
  current_password: Yup.string().required(
    "Bitte geben Sie Ihr altes Passwort ein."
  ),
  password: Yup.string()
    .isValidPassword()
    .required("Bitte geben Sie ein Passwort ein."),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen")
    .required("Bitte Wiederholen Sie das neue Passwort!"),
});

export default editPasswordScheme;
